$PERCENTAGE_FONT_SIZE: 22px;
$FIXED_FONT_SIZE: 17px;
$TEXT_WIDTH: 57px;

//---------------------------
// Radial Mixins
//---------------------------

// Based on: https://medium.com/@andsens/radial-progress-indicator-using-css-a917b80c43f9
@mixin radial-gauge($font-size: $PERCENTAGE_FONT_SIZE) {
    // constants
    $INSET_COLOR: $COLOR_V3_WHITE;
    $SHADOW: 6px 6px 10px rgba(0, 0, 0, 0);
    $TRANSITION_LENGTH: 1s;

    background-color: $COLOR_V3_BEIGE;
    border-radius: 50%;

    // override display value from assessment_lesson_end_fallbacks.scss
    display: block;
    text-align: left;

    .circle {
        .fill,
        .shadow {
            border-radius: 50%;
        }
        .mask,
        .fill,
        .shadow {
            position: absolute;
        }
        .shadow {
            box-shadow: $SHADOW inset;
        }
        .mask,
        .fill {
            -webkit-backface-visibility: hidden;
            transition: transform $TRANSITION_LENGTH;
        }
    }

    .inset {
        position: absolute;
        background-color: $INSET_COLOR;
        border-radius: 50%;
        box-shadow: $SHADOW;

        .percentage,
        .fixed-value {
            height: $font-size;
            width: $TEXT_WIDTH;
            overflow: hidden;
            position: absolute;
            line-height: 1;
            .numbers {
                margin-top: -$font-size;
                transition: width $TRANSITION_LENGTH;
                span {
                    width: $TEXT_WIDTH;
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    font-size: $font-size;
                    font-weight: $FONT_WEIGHT_SEMIBOLD;
                }
            }
        }

        .fixed-value {
            height: $FIXED_FONT_SIZE;
            text-align: center;
            font-size: $FIXED_FONT_SIZE;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            .numbers {
                margin-top: -$FIXED_FONT_SIZE;
            }
        }
    }

    .caption {
        display: block;
        position: absolute;
        bottom: -25px;
        left: 0px;
        right: 0px;

        text-align: center;
        font-size: 13px;
        font-weight: $FONT_WEIGHT_NORMAL;
        text-transform: uppercase;
    }

    $increment: 180deg * 0.01;
    @for $i from 0 through 100 {
        &[data-gauge="#{$i}"] {
            .circle {
                .mask.full,
                .fill {
                    transform: rotate($increment * $i);
                }
                .fill.fix {
                    transform: rotate($increment * $i * 2);
                }
            }
            .inset .percentage .numbers {
                width: $i * $TEXT_WIDTH + $TEXT_WIDTH;
            }
        }
    }
}

@mixin radial-gauge-size-overrides($circle-size: 120px, $circle-width: 30px, $font-size: $PERCENTAGE_FONT_SIZE) {
    $inset-size: $circle-size - $circle-width;

    width: $circle-size;
    height: $circle-size;

    .circle {
        .mask,
        .fill,
        .shadow {
            width: $circle-size;
            height: $circle-size;
        }
        .mask {
            clip: rect(0px, $circle-size, $circle-size, $circle-size*0.5);
            .fill {
                clip: rect(0px, $circle-size*0.5, $circle-size, 0px);
            }
        }
    }

    .inset {
        width: $inset-size;
        height: $inset-size;
        margin-left: ($circle-size - $inset-size)*0.5;
        margin-top: ($circle-size - $inset-size)*0.5;

        .percentage {
            top: ($inset-size - $font-size) * 0.5;
            left: ($inset-size - $TEXT_WIDTH) * 0.5;
        }

        .fixed-value {
            top: ($inset-size - $FIXED_FONT_SIZE) * 0.5;
            left: ($inset-size - $TEXT_WIDTH) * 0.5;
        }
    }
}

@mixin radial-gauge-color-overrides($circle-color: $COLOR_V3_GREEN) {
    .circle .mask .fill {
        background-color: $circle-color;
    }

    .inset {
        .percentage,
        .fixed-value {
            .numbers span {
                color: $circle-color;
            }
        }

        .fixed-value {
            color: $circle-color;
        }
    }

    .caption {
        color: $circle-color;
    }
}
