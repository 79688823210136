// add the 'div' so that this will be more specific
// than assessment_lesson_end_fallbacks, and will
// override those styles
div.assessment-lesson-end {
    position: relative;
    display: block;
    height: 120px;
    margin-bottom: 20px;

    animation: fade-in 0.5s;

    direction: ltr;

    .score-gauge,
    .frames-gauge,
    .challenges-gauge {
        @include radial-gauge();
        @include radial-gauge-size-overrides(90px, 20px);
    }

    .score-gauge {
        @include radial-gauge-size-overrides(120px, 30px);
        @include radial-gauge-color-overrides($COLOR_V3_GREEN);
        position: absolute;
        top: 0px;
        left: calc(50% - 60px + 5px);
    }

    .frames-gauge {
        @include radial-gauge-color-overrides($COLOR_V3_BLUE);
        position: absolute;
        left: 10px;
        top: 15px;

        .caption {
            white-space: nowrap;
        }

        &.score-hidden {
            left: calc(50% - 90px);

            @media (min-width: $screen-sm-min) {
                left: calc(50% - 110px);
            }
        }
    }

    .challenges-gauge {
        @include radial-gauge-color-overrides($COLOR_V3_YELLOW);
        position: absolute;
        right: 10px;
        top: 15px;

        &.score-hidden {
            right: calc(50% - 90px);

            @media (min-width: $screen-sm-min) {
                right: calc(50% - 110px);
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        .score-gauge {
            @include radial-gauge-size-overrides(110px, 25px);
        }

        .frames-gauge,
        .challenges-gauge {
            @include radial-gauge-size-overrides(70px, 15px);
            top: 20px;
            .caption {
                font-size: 11px;
            }
        }
        .frames-gauge {
            left: 5px;
        }
        .challenges-gauge {
            right: 5px;
        }
    }
}
